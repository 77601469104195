.auth_call_form {
  &--domestic h4, &--overseas h4 {
    margin-bottom: 15px;
  }
  &--domestic_switch_mode, &--overseas_switch_mode {
    button {
      color: #537dc9;
    }
  }
  &--overseas_select_country, &--overseas_phone_number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    label {
      margin-right: 10px;
    }
  }
  &--overseas_select_country {
    select {
      width: 400px;
    }
  }
  &--overseas_phone_number {
    &--input_group {
      width: 400px;
      input:first-child {
        width: 30%;
      }
      input:last-child {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .auth_call_form {
    &--overseas_select_country, &--overseas_phone_number {
      flex-direction: column;
    }
    &--overseas_select_country select {
        min-width: 100%;
        max-width: 100%;
        flex: 1;
    }
    &--overseas_phone_number {
      &--input_group {
        min-width: 100%;
        max-width: 100%;
        flex: 1;
      }
    }
    &--domestic input {
      width: 100%;
    }
  }
}
