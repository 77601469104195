//------------------------------------------------------------ Import file ---*/
@import "default";
//------------------------------------------------------------ Media mixin ---*/
$sp: 1023px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 1023;
@function pv($num, $width: $viewport) {
  @return (1vw * $num / $width * 100);
}

//--------------------------------------------------------------- Contents ---*/
html {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  outline: none;
}

body {
  width: 100%;
  height: 100%;
  //--- 基本文字色の設定 ---*/
  color: #000;
  @apply box-border text-black;
  -webkit-text-size-adjust: 100%;
}

//--- リンクカラー ---*/

a {
  color: #1A0DAB;
}

//--- オンマウスで透過 ---*/

a:hover {
  opacity: 0.7;
}

//--- PC.SPで表示・非表示を切り分けたい場合 ---*/

.pc {
  display: block;
}

.sp {
  display: none;
}

@include sp {
  .pc {
    display: none;
  }

  .sp {
    display: block;
  }
}

//--- PC.SPで改行位置を変えたい場合 ---*/

.br-pc {
  display: block;
}

.br-sp {
  display: none;
}

@include sp {
  .br-pc {
    display: none;
  }

  .br-sp {
    display: block;
  }
}

//--- テキストやブロックの左右・中央揃え ---*/

.ctext {
  text-align: center;
}

.rtext {
  text-align: right;
}

.ltext {
  text-align: left;
}

.mauto {
  margin: 0 auto;
}

.ilblock {
  display: inline-block;
}

//--- h1-span での文字サイズ ---*/

h1 {
  font-size: 36px;
  font-weight: normal;
}

h2 {
  font-size: 32px;
  font-weight: normal;
}

h3 {
  font-size: 28px;
  font-weight: normal;
}

h4 {
  font-size: 24px;
  font-weight: normal;
}

h5 {
  font-size: 20px;
  font-weight: normal;
}

h6 {
  font-size: 18px;
  font-weight: normal;
}

p {
  font-size: 16px;
  font-weight: normal;
}

span {
  font-size: 14px;
  font-weight: normal;
}

@include sp {

  h1 {
    font-size: pv(72);
    font-weight: normal;
  }

  h2 {
    font-size: pv(64);
    font-weight: normal;
  }

  h3 {
    font-size: pv(56);
    font-weight: normal;
  }

  h4 {
    font-size: pv(48);
    font-weight: normal;
  }

  h5 {
    font-size: pv(40);
    font-weight: normal;
  }

  h6 {
    font-size: pv(37);
    font-weight: normal;
  }

  p {
    font-size: pv(34);
    font-weight: normal;
  }

  span {
    font-size: pv(31);
    font-weight: normal;
  }
}

.indent {
  padding-left: 1em;
  text-indent: -1em;
}

.b {
  font-weight: bold;
}

//--- 文字サイズ ---*/

$fontSizeConfig: '10' 10px pv(25),
'12' 12px pv(28),
'14' 14px pv(31),
'16' 16px pv(34),
'18' 18px pv(37),
'20' 20px pv(41),
'22' 22px pv(44),
'24' 24px pv(47),
'26' 26px pv(50),
'28' 28px pv(53),
'30' 30px pv(56);

@each $tuple in $fontSizeConfig {
  .block-button {
    .fs#{nth($tuple, 1)} {
      h1, h2, h3, h4, h5, h6, p, span {
        font-size: nth($tuple, 2) !important;
      }
    }
  }

  .fs#{nth($tuple, 1)} {
    font-size: nth($tuple, 2) !important;
  }

  @include sp {
    .fs#{nth($tuple, 1)} {
      font-size: nth($tuple, 3) !important;

      h1, h2, h3, h4, h5, h6, p, span {
        font-size: nth($tuple, 3) !important;
      }
    }
  }
}

//--- 行間設定 line-height 1〜2.5 ---*/

$lineHeightConfig: '10' 1.0 1.0,
'11' 1.1 1.1,
'12' 1.2 1.2,
'13' 1.3 1.3,
'14' 1.4 1.4,
'15' 1.5 1.5,
'16' 1.6 1.6,
'17' 1.7 1.7,
'18' 1.8 1.8,
'19' 1.9 1.9,
'20' 2.0 2.0,
'21' 2.1 2.1,
'22' 2.2 2.2,
'23' 2.3 2.3,
'24' 2.4 2.4,
'25' 2.5 2.5;

@each $tuple in $lineHeightConfig {
  .lh#{nth($tuple, 1)} {
    line-height: nth($tuple, 2) !important;
  }

  @include sp {
    .lh#{nth($tuple, 1)} {
      line-height: nth($tuple, 3) !important;
    }
  }
}

//--- マージン設定(margin-top / bottom / left / right 5px-200px) ---*/

$marginConfig: '5' 5px pv(5),
'10' 10px pv(10),
'20' 20px pv(20),
'30' 30px pv(30),
'40' 40px pv(40),
'50' 50px pv(50),
'60' 60px pv(60),
'70' 70px pv(70),
'80' 80px pv(80),
'90' 90px pv(90),
'100' 100px pv(100),
'110' 110px pv(110),
'120' 120px pv(120),
'130' 130px pv(130),
'140' 140px pv(140),
'150' 150px pv(150),
'160' 160px pv(160),
'170' 170px pv(170),
'180' 180px pv(180),
'190' 190px pv(190),
'200' 200px pv(200);

@each $tuple in $marginConfig {
  .mt#{nth($tuple, 1)} {
    margin-top: nth($tuple, 2) !important;
  }

  @include sp {
    .mt#{nth($tuple, 1)} {
      margin-top: nth($tuple, 3) !important;
    }
  }

  .mb#{nth($tuple, 1)} {
    margin-bottom: nth($tuple, 2) !important;
  }

  @include sp {
    .mb#{nth($tuple, 1)} {
      margin-bottom: nth($tuple, 3) !important;
    }
  }

  .ml#{nth($tuple, 1)} {
    margin-left: nth($tuple, 2) !important;
  }

  @include sp {
    .ml#{nth($tuple, 1)} {
      margin-left: nth($tuple, 3) !important;
    }
  }

  .mr#{nth($tuple, 1)} {
    margin-right: nth($tuple, 2) !important;
  }

  @include sp {
    .mr#{nth($tuple, 1)} {
      margin-right: nth($tuple, 3) !important;
    }
  }
}

//--- パディング設定(padding-top / bottom / left / right 5px-200px) ---*/

$paddingConfig: '5' 5px pv(5),
'10' 10px pv(10),
'20' 20px pv(20),
'30' 30px pv(30),
'40' 40px pv(40),
'50' 50px pv(50),
'60' 60px pv(60),
'70' 70px pv(70),
'80' 80px pv(80),
'90' 90px pv(90),
'100' 100px pv(100),
'110' 110px pv(110),
'120' 120px pv(120),
'130' 130px pv(130),
'140' 140px pv(140),
'150' 150px pv(150),
'160' 160px pv(160),
'170' 170px pv(170),
'180' 180px pv(180),
'190' 190px pv(190),
'200' 200px pv(200);

@each $tuple in $paddingConfig {
  .pt#{nth($tuple, 1)} {
    padding-top: nth($tuple, 2) !important;
  }

  @include sp {
    .pt#{nth($tuple, 1)} {
      padding-top: nth($tuple, 3) !important;
    }
  }

  .pb#{nth($tuple, 1)} {
    padding-bottom: nth($tuple, 2) !important;
  }

  @include sp {
    .pb#{nth($tuple, 1)} {
      padding-bottom: nth($tuple, 3) !important;
    }
  }

  .pl#{nth($tuple, 1)} {
    padding-left: nth($tuple, 2) !important;
  }

  @include sp {
    .pl#{nth($tuple, 1)} {
      padding-left: nth($tuple, 3) !important;
    }
  }

  .pr#{nth($tuple, 1)} {
    padding-right: nth($tuple, 2) !important;
  }

  @include sp {
    .pr#{nth($tuple, 1)} {
      padding-right: nth($tuple, 3) !important;
    }
  }
}

//--- 横幅サイズ設定(width 5%-100%) ---*/

$widthConfig: '5' 5% 5vw,
'10' 10% 10vw,
'15' 15% 15vw,
'20' 20% 25vw,
'25' 25% 25vw,
'30' 30% 30vw,
'35' 35% 35vw,
'40' 40% 40vw,
'45' 45% 45vw,
'50' 50% 50vw,
'55' 55% 55vw,
'60' 60% 60vw,
'65' 65% 65vw,
'70' 70% 70vw,
'75' 75% 75vw,
'80' 80% 80vw,
'85' 85% 85vw,
'90' 90% 90vw,
'95' 95% 95vw,
'100' 100% 100vw;

@each $tuple in $widthConfig {
  .w#{nth($tuple, 1)} {
    width: nth($tuple, 2) !important;
  }

  @include sp {
    .w#{nth($tuple, 1)} {
      width: nth($tuple, 3) !important;
    }
  }
}

//--- 文字太さ設定(font-weight 100-900) ---*/

$fontWeightConfig: '100' 100 100,
'200' 200 200,
'300' 300 300,
'400' 400 400,
'500' 500 500,
'600' 600 600,
'700' 700 700,
'800' 800 800,
'900' 900 900;

@each $tuple in $fontWeightConfig {
  .fw#{nth($tuple, 1)} {
    font-weight: nth($tuple, 2) !important;

    h1, h2, h3, h4, h5, h6, p, span {
      font-weight: nth($tuple, 2) !important;
    }
  }

  @include sp {
    .fw#{nth($tuple, 1)} {
      font-weight: nth($tuple, 3) !important;

      h1, h2, h3, h4, h5, h6, p, span {
        font-weight: nth($tuple, 2) !important;
      }
    }
  }
}

//--- 質問専用の横幅サイズ設定(question only - width 5-100) ---*/

$boxWidthConfig: '5' 5% 5vw,
'10' 10% 10vw,
'15' 15% 15vw,
'20' 20% 25vw,
'25' 25% 25vw,
'30' 30% 30vw,
'35' 35% 35vw,
'40' 40% 40vw,
'45' 45% 45vw,
'50' 50% 50vw,
'55' 55% 55vw,
'60' 60% 60vw,
'65' 65% 65vw,
'70' 70% 70vw,
'75' 75% 75vw,
'80' 80% 80vw,
'85' 85% 85vw,
'90' 90% 90vw,
'95' 95% 95vw,
'100' 100% 100vw;

@each $tuple in $boxWidthConfig {
  .f-box-w#{nth($tuple, 1)} {
    input:not(.db-array-ocr input), select {
      width: nth($tuple, 2) !important;
    }

    .db-f-box-multiple {
      display: block !important;
    }
  }

  @include sp {
    .f-box-w#{nth($tuple, 1)} {
      input:not(.db-array-ocr input), select {
        width: nth($tuple, 3) !important;
      }

      .db-f-box-multiple {
        display: block !important;
      }
    }
  }
}

//--- Breakpoint ---*/

#cw780 {
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
}

#cw960 {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

#cw1000 {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

#cw1040 {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
}

#cw1100 {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

#cw1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@include sp {

  #cw780 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5vw;
  }

  #cw960 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5vw;
  }

  #cw1000 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5vw;
  }

  #cw1040 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5vw;
  }

  #cw1100 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5vw;
  }

  #cw1200 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 5vw;
  }


}

.abtn {
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 0 auto;

  a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-indent: -999px;
    z-index: 2;
  }

}

a:hover {
  filter: alpha(opacity=70);
  -ms-filter: "alpha(opacity=70)";
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
  zoom: 1;
  transition: all .9s;
}

// .block-html {
//   max-width: 1200px;
//   margin: 0 auto;
// }

.block-button {
  display: block;
  text-align: center;
}


//------------------------------------------------------------ Contents SP ---*/
@include sp {

  body {
    width: 100%;
    height: 100vh;
  }

  img {
    max-width: 100%;
  }

  .pc {
    display: none;
  }

  .sp {
    display: block;
  }

  .br-sp {
    display: block;
  }

  .br-pc {
    display: none;
  }

  .block-html {
    max-width: 100vw;
    margin: 0 auto;
  }


}


/*Add class new*/

.block-width {
  max-width: 896px;
  position: relative;
  margin: 0 auto;
}

@include sp {

  .block-width {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
}

:root {
  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: start;
  }

  .text-right {
    text-align: end;
  }

  .img-center {
    margin-left: auto;
    margin-right: auto;
  }

  .img-left {
    margin-left: 0;
    margin-right: auto;
  }

  .img-right {
    margin-left: auto;
    margin-right: 0;
  }

  .flex-center {
    display: flex;
    justify-content: center
  }

  .flex-left {
    display: flex;
    justify-content: left;
  }

  .flex-right {
    display: flex;
    justify-content: right;
  }
}

/*Dohbo Viet Nam*/
body.vi {
  @include sp {
    p {
      font-size: pv(41) !important;
    }
  }
}
