@tailwind base;
@tailwind components;
@tailwind utilities;

// Variables + Mixins + Functions
@import "helpers/variables";

// Always include variable 1st !
@import "helpers/mixins";

// Base
@import "base";

// Layout
@import "layout";

// Page
@import "page";

// Components
@import "components";
