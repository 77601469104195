.shared-modal-wrapper {
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  text-align: center;
  transition: 0.2s ease-in-out;

  .modal-overlay {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  .modal-window {
    box-sizing: border-box;
    z-index: 20;
    height: auto;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    padding: 0;
    width: 100%;

    @media (max-width: 780px) {
      width: 90vw;
    }
  }

  .modal-cancel {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    background-image: url(../../images/common/svg/close_btn.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    min-width: inherit;
    z-index: 99;
    cursor: pointer;

    @media (max-width: 780px) {
      position: absolute;
      top: 3vw;
      right: 3vw;
      width: 8vw;
      height: 8vw;
    }
  }

  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 600px;
    background-color: #fff;
    border-radius: 0;
    margin: 0 auto;
    padding: 35px;
    position: relative;

    @media (max-width: 780px) {
      padding: 10vw 5vw;
      background: #fff;
    }

    .text-message {
      line-height: 1.4;
      font-weight: 700;
    }

    p {
      line-height: 1;
      margin: 0 auto 15px;

      @media (max-width: 780px) {
        font-size: 3.07692vw;
        line-height: 1;
        margin: 0 auto 3vw;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 1;
      margin: 0 auto 20px;
      white-space: break-spaces;

      @media (max-width: 780px) {
        font-size: 4.10256vw;
        font-weight: 700;
        line-height: 1;
        margin: 0 auto 5vw;
      }
    }

    .abtn, .btn {
      @media (max-width: 1023px) {
        width: 100%;
      }

      &.back_btn {
        background-image: none;
        @media (max-width: 780px) {
          margin-bottom: 0;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}
