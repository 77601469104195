$sp_small: 425px;
$sp: 1023px;
$pc: 1024px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin pc {
  @media (min-width: ($sp)) {
    @content;
  }
}

@mixin sp_small {
  @media (max-width: ($sp_small)) {
    @content;
  }
}

:root {
  .db-array-ocr {
    position: relative;

    input[type=text] {
      width: 50%;
    }

    @include sp_small {
      input[type=text] {
        width: 70%;
      }
    }
  }

  .db-icon-ocr {
    position: absolute;
    top: 20%;
    left: 50%;
    @include sp_small {
      left: 75%;
    }
  }

  i.disabled {
    color: aliceblue;
    pointer-events: none;
  }

  .fz-icon-ocr {
    font-size: 30px;
    @include sp {
      font-size: 40px;
    }
    @include sp_small {
      font-size: 25px;
    }
  }

  .box-center {
    text-align: center;

    .element_flex, label, .box_inner {
      justify-content: center;
    }

    .uploadValue {
      margin: 0 auto;

      input[type=text] {
        text-align: center;
      }
    }

    .file::after {
      left: 50%;
      transform: translate(-50%, 0);
    }

    .db-icon-ocr {
      left: 75%;
    }

    .image-preview {
      margin: 0 auto;
    }
  }

  .box-left {
    text-align: start;

    .element_flex, label, .box_inner {
      justify-content: left;
    }

    .uploadValue input[type=text] {
      text-align: start;
    }

    .file::after {
      left: 0;
    }

    .db-array-ocr {
      @include sp {
        input[type=text] {
          width: 80%;
        }
        .db-icon-ocr {
          left: 85%;
        }
      }
    }

    .image-preview {
      margin: 0;
    }
  }

  .box-right {
    text-align: end;

    .element_flex, label, .box_inner {
      justify-content: right;
    }

    .uploadValue {
      margin-left: auto;

      input[type=text] {
        text-align: end;
      }
    }

    .file::after {
      left: 100%;
      transform: translate(-100%, 0);
      @include pc {
        width: max-content;
      }
    }

    .db-icon-ocr {
      left: 40%;
      @include sp {
        left: 40%;
      }
    }

    .db-array-ocr {
      @include sp {
        input[type=text] {
          width: 80%;
        }
        .db-icon-ocr {
          left: 0;
        }
      }
    }

    .image-preview {
      margin: 0 0 0 auto;
    }
  }

  /*------ BUTTON SUBMIT INPUT ------*/
  .btn-form-center {
    margin: 0 auto 20px;

    input[type=button] {
      margin: 0 auto 20px;
    }
  }

  .btn-form-left {
    margin: 0 0 20px 0;

    input[type=button] {
      margin: 0 0 20px 0;
    }
  }

  .btn-form-right {
    margin: 0 0 20px auto;

    input[type=button] {
      margin: 0 0 20px auto;
    }
  }
}


