.block {
  .gift {
    width: 60%;
    background-color: #fff;
    border: 3px solid #F40009;
    text-align: center;
    margin: 0 auto;
    &.paypay {
      width: 100%;
    }
    .ttl {
      font-weight: bold;
      color: #fff;
      width: 100%;
      background-color: #F40009;
      padding: min(3.205vw, 25px) 0;
    }

    .gift_content {
      overflow-wrap: break-word;
      font-weight: bold;
      padding: min(3.205vw, 25px) 0;
      line-height: 1.6;

      .barcode {
        svg {
          max-width: 100%;
          display: block;
          margin: auto;
        }
      }
      .qrcode {
        img {
          display: block;
          margin: auto;
          width: 300px;
        }
      }
    }

  }

  .gift_content__additional_data {
    // width: 410px;
    @apply flex justify-center flex-col my-1;
    p {
      @apply flex my-1;
    }
  }

  @include sp {
    .gift {
      width: 70%;
    }
  }

  .paypay_content {
    padding-top: 10px;
    width: 100%;
  }

  .zalo_pay_content {
    padding-top: 10px;
    width: 100%;
  }
}
