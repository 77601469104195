/* Expect default block */
.block {
  img {
    margin-left: auto;
    margin-right: auto;
  }

  a {
    &.disabled {
      cursor: default;

      &:hover {
        opacity: 1;
      }

      img {
        filter: grayscale(1);
      }
    }
  }

  &.block-html {
    text-align: center;
  }

  &.block-serial-password {
    text-align: center;

    h4 {
      margin: 10px 0;
    }

    input[type=text] {
      min-width: 400px;
      @include sp {
        min-width: 80vw;
      }
    }
  }

  .block-gift-content {
    width: 410px;
    margin-left: auto;
    margin-right: auto;
    @include sp {
      width: 80vw;
    }
  }

  &.block-call-auth {
    text-align: center;
  }

  &.block-display-point,
  &.block-request-point,
  &.block-display-course {
    text-align: center;
  }

  &.block-request-point {
    input[type=text], input[type=tel] {
      min-width: 410px;
      @include sp {
        min-width: 80vw;
      }
    }

    .snapshot {
      img {
        margin: 16px auto;

        @include sp {
          margin: 2.5vw auto;
          height: auto;
          width: 80vw;
          background-size: 8vw 8vw;
        }
      }
    }

    .error_s {
      white-space: break-spaces;
      margin-bottom: 1rem;

      @include sp {
        margin: 2.5vw auto;
      }
    }
  }

  .btn {
    &.btn-qr {
      background-image: url(../../images/common/svg/qr.svg);
      background-repeat: no-repeat;
      background-position: 5% center;
    }
  }

  .btn-qr,
  .btn-camera,
  .btn-barcode {
    max-height: 90px;
    width: 410px;
    background: none;
    background-color: #1f1f1f;
    border: solid 1px #1f1f1f;
    border-radius: 3px;
    margin: 16px auto;
    display: block;
    background-size: 52px 52px;
    text-decoration: none;
    padding: 45px 0;
    text-align: center;
    position: relative;

    @include sp {
      height: auto;
      width: 80vw;
      margin: 2.5vw auto;
      background-size: 8vw 8vw;
      padding: 8vw 0;
    }

    span {
      color: #FFF;
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);

      @include sp {
        font-size: 3.58974vw;
        position: absolute;
      }
    }
    &.disabled-btn {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.block-inactivate {
    text-align: center;
    margin: 10px 0;

    input[type=text] {
      min-width: 400px;
      @include sp {
        min-width: 80vw;
      }
    }
  }
}

/* Expect Custom Class */
:root {
  /*====== Block Button Style Button ======*/
  .block-btn-center {
    margin-left: auto;
    margin-right: auto;
  }

  .block-btn-left {
    margin-left: 0;
    margin-right: auto;
  }

  .block-btn-right {
    margin-left: auto;
    margin-right: 0;
  }

  /*====== End ======*/

  /*====== Block Button Style Image ======*/
  .block-btn-img-center {
    margin-left: auto;
    margin-right: auto;
  }

  .block-btn-img-left {
    margin-left: 0;
    margin-right: auto;
  }

  .block-btn-img-right {
    margin-left: auto;
    margin-right: 0;
  }

  /*====== End ======*/

  /*====== Block Image ======*/
  .block-img-center {
    margin-left: auto;
    margin-right: auto;
  }

  .block-img-left {
    margin-left: 0;
    margin-right: auto;
  }

  .block-img-right {
    margin-left: auto;
    margin-right: 0;
  }

  /*====== End ======*/

  /*====== Block HTML ======*/
  .block-html-center {
    text-align: center;
  }

  .block-html-left {
    text-align: start;
  }

  .block-html-right {
    text-align: end;
  }

  /*====== End ======*/

  /*====== Block Serial ======*/
  .block-serial-center {
    text-align: center;

    input[type=button] {
      margin: 40px auto 20px;
    }
  }

  .block-serial-left {
    text-align: start;

    input[type=button] {
      margin: 40px auto 20px 0;
    }
  }

  .block-serial-right {
    text-align: end;

    input[type=button] {
      margin: 40px 0 20px auto;
    }
  }

  /*====== End ======*/

  /*====== Block Gift ======*/
  /*type = text*/
  .block-gift-text-center {
    margin-left: auto;
    margin-right: auto;

    .gift {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .block-gift-text-left {
    margin-left: 0;
    margin-right: auto;

    .gift {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .block-gift-text-right {
    margin-left: auto;
    margin-right: 0;

    .gift {
      margin-left: auto;
      margin-right: 0;
    }
  }

  /*type = barcode*/
  .block-gift-barcode-center {
    .gift {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .block-gift-barcode-left {
    .gift {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .block-gift-barcode-right {
    .gift {
      margin-left: auto;
      margin-right: 0;
    }
  }

  /*type = qrcode*/
  .block-gift-qrcode-center {
    .gift {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .block-gift-qrcode-left {
    .gift {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .block-gift-qrcode-right {
    .gift {
      margin-left: auto;
      margin-right: 0;
    }
  }

  /*type = url*/
  .block-gift-url-center {
    .gift {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .block-gift-url-left {
    .gift {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .block-gift-url-right {
    .gift {
      margin-left: auto;
      margin-right: 0;
    }
  }

  /*type = button*/
  .block-gift-button-center {
    display: flex;
    justify-content: center
  }

  .block-gift-button-left {
    display: flex;
    justify-content: left;
  }

  .block-gift-button-right {
    display: flex;
    justify-content: right;
  }

  /*====== End ======*/

  /*====== Block Button Agree ======*/
  .block-agree-button-center {
    input[type=button] {
      margin: 40px auto 20px;
    }
  }

  .block-agree-button-left {
    input[type=button] {
      margin: 40px auto 20px 0;
    }
  }

  .block-agree-button-right {
    input[type=button] {
      margin: 40px 0 20px auto;
    }
  }

  /*====== End ======*/
}
