.btn {
  min-height: 90px;
  width: 410px;
  background: none;
  background-color: #e60012;
  border-radius: 3px;
  margin: 20px auto;
  color: #FFF;
  display: block;
  background-position: 5% center;
  background-size: 20px 10px;
  text-decoration: none;
  padding: 35px 10px;
  text-align: center;
  cursor: pointer;
  line-height: 1.4;

  &:hover {
    filter: alpha(opacity=70);
    -ms-filter: "alpha(opacity=70)";
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
    opacity: 0.7;
    zoom: 1;
    transition: all .9s;
  }

  &.loading {
    border: solid 1px #b1b1b1;
    background: #b1b1b1 no-repeat 95% center;
    pointer-events: none;
  }

  &.btn-disabled {
    background-color: #cccccc !important;
    border: solid 1px #cccccc;
    color: #FFFFFF;
    cursor: default;

    &:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  @include sp {
    font-size: 16px;
  }
}

.db-form-button {
  margin-bottom: 50px;
  margin-top: 50px;

  img {
    margin: 0 auto 20px;
  }
}

@include sp {
  .btn {
    height: auto;
    width: 80vw;
    margin: 5vw auto 2.5vw;
    padding: 7vw 0;
  }
}

.btn02 {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto 40px;
  padding: 0;
  cursor: pointer;
}

.btn02:hover {
  zoom: 1;
  transition: all .7s;
  opacity: .8;
  filter: alpha(opacity=80);
  -ms-filter: 'alpha(opacity=80)';
  -moz-opacity: .8;
  -khtml-opacity: .8;
}

.btn02 a {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent: -999px;
}

.btn02 {
  border-radius: 3px;
  height: 125px;
  width: 624px;
  margin: 30px auto 0;
  position: relative;
}

.btn p, .btn02 p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 26px;
  text-align: center;
  color: #fff;
  line-height: 1.2;
  font-weight: bold;
  white-space: nowrap;
}

.btn p:before, .btn02 p:before {
  content: "";
  margin-top: 0 !important;
}

.btn input, .btn02 input {
  display: none;
}

.btn02 {
  height: 90px !important;
  width: 468px !important;
  margin: 100px auto 10px !important;
}

.btn02 p {
  color: #000;
}

.gr {
  background-color: #E9E9E9;
  border: none;

  &.disabled {
    opacity: .5;
    pointer-events: none;
    cursor: auto;
  }
}

@media (max-width: 780px) {
  .btn {
    border-radius: 3px;
    height: 20vw;
    width: 80vw;
    margin: 4vw auto 0;
    position: relative;
  }
  .btn p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 3.84615vw;
    line-height: 1.2;
    font-weight: bold;
    white-space: nowrap;
  }
  .btn p:before {
    content: "";
    margin-top: 0 !important;
  }
  .btn input {
    display: none;
  }
  .btn02 {
    border-radius: 3px;
    height: 15vw !important;
    width: 60vw !important;
    margin: 12vw auto 6vw !important;
    position: relative;
  }
  .btn02 p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    font-size: 3.07692vw;
    line-height: 1.2;
    font-weight: bold;
    white-space: nowrap;
  }
  .btn02 p:before {
    content: "";
    margin-top: 0 !important;
  }
}
