@import "projects/app-shared/src/assets/scss/base/default";
/*----------------------------Color Setteing */
$blue: #3E8BF9;
$error: #FDB8BA;
$red: #D80C18;
$gray: #787878;
$line: #dcdcdc;
$stepon: #000000;
$stepoff: #EEEEEE;
$active: #FDFCE2;
/*----------------------------  Divice Font Size */
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

$sp: 1023px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 1023;
@function pv($num, $width: $viewport) {
  @return (1vw * $num / $width * 100);
}


/*---------------------------- Layout Base Style */

body {
  width: 100%;
  height: 100%;
  // font-family:"Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, "メイリオ", sans-serif !important;
  // font-family: 'Noto Sans JP', sans-serif;
  font-size: 100%;
  color: #000;
}

* {
  box-sizing: border-box;
}

a {
  color: #1A0DAB;
}


.roboto {
  font-family: 'Roboto Condensed', sans-serif;
}

//LAYOUT
#wrapper {
  width: 100%;
  margin: 0 auto;
}


.wrap {
  max-width: 896px;
  position: relative;
  margin: 0 auto;
}

p {
  font-size: 16px;
}

@include sp {

  #wrapper {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .kv {
    margin: 0 auto 8vw;
    max-width: 100%;
  }

  .wrap {
    max-width: 90vw;
    position: relative;
    margin: 0 auto;
  }

  p {
    font-size: pv(34);
  }

}


//STEP LAYOUT
.step_flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  column-gap: 2px;
  margin: 0 auto 50px;

  .step_bx_on {
    background-color: $stepon;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    position: relative;

    span {
      display: block;
      font-size: 12px;
      color: #fff;
      margin-bottom: 5px;
      line-height: 1;
    }

    p {
      display: block;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      line-height: 1;
    }
  }


  .step_bx {
    background-color: $stepoff;
    width: 100%;
    text-align: center;
    padding: 20px 0;

    span {
      display: block;
      font-size: 12px;
      margin-bottom: 5px;
      line-height: 1;
      margin-right: 0;
    }

    p {
      display: block;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
    }
  }

}

@include sp {
  .step_flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    column-gap: 1px;
    margin: 0 auto 5vw;

    .step_bx_on {
      background-color: $stepon;
      width: 100%;
      text-align: center;
      padding: 3vw 0;
      position: relative;

      span {
        display: block;
        font-size: pv(22);
        color: #fff;
        margin-bottom: 1vw;
      }

      p {
        display: block;
        font-size: pv(32);
        font-weight: bold;
        color: #fff;
      }
    }


    .step_bx {
      background-color: $stepoff;
      width: 100%;
      text-align: center;
      padding: 3vw 0;

      span {
        display: block;
        font-size: pv(22);
        margin-bottom: 1vw;
      }

      p {
        display: block;
        font-size: pv(32);
        font-weight: bold;
      }
    }

  }
}

// ERROR MESSAGE
.error_l {
  font-size: 20px;
  font-weight: bold;
  color: #e50012;
  text-align: center;
  margin: 30px auto;
}

.error_s {
  font-size: 16px;
  font-weight: bold;
  color: #e50012;
  margin-top: 5px;
}

.help, .subtitle {
  color: #1f1f1f;
  white-space: break-spaces;
  word-break: break-word;
}

.title_formly {
  word-break: break-word;
}

@include sp {

  .error_l {
    font-size: pv(34);
    font-weight: bold;
    color: #e50012;
    text-align: center;
    margin: 0 auto 3vw;
  }

  .error_s {
    font-size: pv(30);
    font-weight: bold;
    color: #e50012;
    margin-top: 1vw;
  }

  .help, .subtitle {
    color: #000;
  }

}

// ELEMENT BOX
.box, .radio_box {
  padding: 20px 0;
  border-bottom: 1px solid $line;
  white-space: pre-line;

  h3 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.6;
    margin-bottom: 5px;
  }

  p {
    line-height: 1.6;
  }
}

.thanks {
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.6;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 2;
    margin-bottom: 100px;
  }
}

.noline {
  border-bottom: 0 !important;
}

.box_inner {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.box_inner_lt {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 15px 0 0;
}

.box_inner_bx {
  flex-basis: 100%;
  max-width: 100%;
  text-align: left;
  margin-bottom: 20px;

  .list {
    font-size: 16px;
    line-height: 1;
    margin-left: 15px;
    white-space: break-spaces;
  }

}

.caution {
  margin: 20px auto;

  img {
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 1.6;
  }
}

@include sp {

  .box, .radio_box {
    padding: 6vw 0;
    border-bottom: 1px solid $line;

    h3 {
      font-size: pv(30);
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 1vw;
    }

    p {
      line-height: 1.6;
    }

  }

  .thanks {
    margin: 10vw auto;
    text-align: center;

    h2 {
      font-size: pv(44);
      font-weight: bold;
      line-height: 1.6;
      margin-bottom: 20px;
    }

    p {
      font-size: pv(34);
      line-height: 2;
      margin-bottom: 30vw;
      text-align: left;
    }
  }

  .noline {
    border-bottom: 0 !important;
  }

  .box_inner {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2vw;
  }

  .box_inner_lt {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin: 15px 0 0;
  }

  .box_inner_bx {
    flex-basis: 100%;
    max-width: 100%;
    text-align: left;
    margin-bottom: 4vw;

    .list {
      font-size: pv(41);
      line-height: 1;
      margin: 2vw 0 0 5vw;
    }

  }

  .caution {
    margin: 5vw auto;

    img {
      margin-bottom: 2vw;
    }

    p {
      font-size: pv(24);
      line-height: 1.6;
    }
  }

}


// ELEMENT TITLE
.element_flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    border-radius: 3px;
    padding: 4px 10px;
    margin: 4px 10px 10px 0;
    white-space: nowrap;
  }

  .required {
    background-color: $red;
  }

  .option {
    background-color: #666;
  }

  h4 {
    font-size: 18px;
    line-height: 1.6;
  }

}

@include sp {

  .element_flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    span {
      display: inline-block;
      color: #fff;
      font-size: pv(30);
      font-weight: bold;
      margin-right: 3vw;
      border-radius: 2px;
      padding: 1vw 2vw;
      margin: 1vw 2vw 2vw 0;
      white-space: nowrap;
    }

    .required {
      background-color: $red;
    }

    .option {
      background-color: #666;
    }

    h4 {
      font-size: pv(42);
      line-height: 1.6;
    }

  }

  .center {
    justify-content: center;
  }
}

// FOTTER
footer {
  background-color: #000;
  width: 100%;
  text-align: center;
  padding: 50px 0 70px;
  color: #fff;
  margin-top: 100px;

  h1 {
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    border-radius: 4px;
    border: 1px solid #fff;
    width: 560px;
    padding: 12px 0;
    margin: 0 auto 15px;
  }

  h2 {
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    margin: 0 auto 10px;

    sub {
      bottom: -0.25em;
    }

  }

  h3 {
    font-size: 31px;
    font-weight: bold;
    line-height: 1;
    margin: 0 auto 35px;

    a {
      text-decoration: none;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    margin: 0 auto 10px;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    margin: 0 auto 55px;
  }

  span {
    font-size: 15px;
    font-weight: normal;
    line-height: 1;
    display: inline-block;
  }
}

@include sp {

  footer {
    background-color: #000;
    width: 100%;
    text-align: center;
    padding: 6vw 0 10vw;
    color: #fff;
    margin-top: 10vw;

    h1 {
      font-size: pv(30);
      font-weight: bold;
      line-height: 1;
      border-radius: 0;
      border: 1px solid #fff;
      width: 80vw;
      padding: 2vw 0;
      margin: 0 auto 4vw;
    }

    h2 {
      font-size: pv(30);
      font-weight: bold;
      line-height: 1;
      margin: 0 auto 2vw;
    }

    h3 {
      font-size: pv(44);
      font-weight: bold;
      line-height: 1;
      margin: 0 auto 35px;

      a {
        text-decoration: none;
        color: #fff;
      }
    }

    h4 {
      font-size: pv(26);
      font-weight: bold;
      line-height: 1;
      margin: 0 auto 1vw;
    }

    p {
      font-size: pv(26);
      font-weight: normal;
      line-height: 1;
      margin: 0 auto 5vw;
    }

    span {
      font-size: pv(24);
      font-weight: normal;
      line-height: 1;
      display: inline-block;
    }
  }


}


/*---------------------------- Form Style */

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

* {
  outline: none;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #fff inset;
}

@include sp {

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  img {
    max-width: 100%;
  }
}

// INPUT TEXT

input[type=text], input[type=password] {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-color: #fff;
  height: 60px;
  border: 2px solid $line;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 0 20px;
  margin: 0 0 10px 0;
  font-size: 16px;
}

input[type="text"]:focus, input[type=password]:focus {
  border: 2px solid $blue;
}

::placeholder {
  color: $gray;
}

input:focus::placeholder {
  color: transparent;
}

@include sp {

  input[type=text], input[type=password] {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background-color: #fff;
    height: auto;
    border: 1px solid $line;
    box-sizing: border-box;
    vertical-align: middle;
    padding: 3vw 2vw;
    margin: 0 0 1.5vw 0;
    font-size: pv(34);
  }
}


// INPUT TEL

input[type=tel] {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-color: #fff;
  height: 60px;
  border-radius: 0em;
  border: 2px solid $line;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 0 20px;
  margin: 0 0 10px 0;
  font-size: 16px;
}

input[type="tel"]:focus {
  border: 2px solid $blue;
}

::placeholder {
  color: $gray;
}


@include sp {
  input[type=tel] {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background-color: #fff;
    height: auto;
    border: 1px solid $line;
    box-sizing: border-box;
    vertical-align: middle;
    padding: 3vw 2vw;
    margin: 0 0 1.5vw 0;
    font-size: pv(34);
  }
}

//INPUT EMAIL

input[type=email] {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-color: #fff;
  height: 60px;
  border-radius: 0em;
  border: 2px solid $line;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 0 20px;
  margin: 0 20px 10px 0;
  font-size: 16px;
}

input[type="email"]:focus {
  border: 2px solid $blue;
}

::placeholder {
  color: $gray;
}


@include sp {
  input[type=email] {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background-color: #fff;
    height: auto;
    border: 1px solid $line;
    box-sizing: border-box;
    vertical-align: middle;
    padding: 3vw 2vw;
    margin: 0 0 1.5vw 0;
    font-size: pv(34);
  }
}

//TEXT AREA

textarea {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-color: #fff;
  width: 100%;
  border-radius: 0em;
  border: 2px solid $line;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 0 10px 0;
  font-size: 16px;
}

textarea:focus {
  border: 2px solid $blue;
}

::placeholder {
  color: $gray;
}

@include sp {
  textarea {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background-color: #fff;
    width: 100%;
    border-radius: 0em;
    border: 1px solid $line;
    box-sizing: border-box;
    padding: 3vw 2vw;
    margin: 0 0 1.5vw 0;
    font-size: pv(34);
  }
}


// RADIO SEX

input[type=radio] {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

input[type=radio]:checked + span {
  border: none;
  background-color: $blue;
  color: #fff;
}

.radio_segmented {
  background-color: #b4b4b9;
  border: none;
  color: #fff;
  font-weight: bold;
  padding: 20px 0;
}

label {

  p {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    span {
      width: 200px;
      height: auto;
      box-sizing: border-box;
      text-align: center;
      display: inline-block;
    }
  }
}

@include sp {

  .radio_segmented {
    background-color: #b4b4b9;
    border: none;
    color: #fff;
    font-size: pv(41);
    font-weight: bold;
    padding: 4vw 0;
    margin-right: 2vw;
  }

  label {

    p {
      position: relative;
      display: inline-block;
      vertical-align: middle;

      span {
        width: 28vw;
        height: auto;
        box-sizing: border-box;
        text-align: center;
        display: inline-block;
      }
    }
  }

}

//RADIO BUTTON

.radio_box {

  label {
    // display: block;
    margin-bottom: 20px;

    div {
      display: inline;
      margin: 10px 0 0 20px;
      padding: 0;
      font-size: 16px;
      //font-weight: bold;
      white-space: break-spaces;
    }

    p {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      margin-top: 0;
    }

    p span {
      content: "";
      display: block;
      padding: 0;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 2px solid #DFDFDF;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      box-sizing: border-box;
      background-color: #fff;
    }
  }

  input[type=radio] {
    opacity: 0;
    position: relative;
    z-index: 1;
  }

  input[type=radio]:checked {
    border: 10px solid $blue;
  }

  label p input[type=radio]:checked + span {
    border: 10px solid $blue;
    background-color: #fff;
  }
}

@include sp {

  .radio_box {

    label {
      // display: block;
      margin-bottom: 3vw;

      div {
        display: inline;
        margin: 0 0 0 4vw;
        padding: 0;
        font-size: pv(41);
        //font-weight: bold;
      }

      p {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-top: 0;
      }

      p span {
        content: "";
        display: block;
        padding: 0;
        width: 5vw;
        height: 5vw;
        border-radius: 50%;
        border: 2px solid #DFDFDF;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        box-sizing: border-box;
        background-color: #fff;
      }
    }

    input[type=radio] {
      opacity: 0;
      position: relative;
      z-index: 1;
    }

    input[type=radio]:checked {
      border: pv(18) solid $blue;
    }

    label p input[type=radio]:checked + span {
      border: pv(18) solid $blue;
      background-color: #fff;
    }
  }

}


// SELECT BOX

select {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-color: #fff;
  border-radius: 0;
  border: 2px solid $line;
  box-sizing: border-box;
  width: 250px;
  height: 60px;
  padding: 0 20px;
  font-size: 16px;
  background-image: url(../images/select_arrow.svg);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 20px 10px;
  margin: 0 0 10px 0;
}

@include sp {
  select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background-color: #fff;
    height: auto;
    border-radius: 0;
    border: 1px solid $line;
    box-sizing: border-box;
    width: 60vw;
    padding: 4vw 2vw;
    font-size: pv(34);
    background-image: url(../images/select_arrow.svg);
    background-repeat: no-repeat;
    background-position: right 2vw center;
    background-size: 4vw 2vw;
  }
}

// FILE

.file {
  position: relative;
  top: 0px;
  left: 0px;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  input[type=file] {
    opacity: 0;
    width: 138px;
    height: 50px;
  }

  input[type=text] {
    border: none;
    width: 100%;
    background-color: #fff;
    font-size: 14px;
    padding: 0;
    margin-top: 10px;
    height: 40px;
  }
}

.file::after {
  content: "ファイルを選択";
  display: block;
  height: auto;
  background-color: #1f1f1f;
  text-align: center;
  line-height: 50px;
  color: rgb(255, 255, 255);
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 0.3em;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
  padding: 0 10px;
}

@include sp {

  .file {

    input[type=file] {
      opacity: 0;
      width: 100%;
      height: 15vw;
    }

    input[type=text] {
      border: none;
      width: 100%;
      background-color: #fff;
      font-size: pv(34);
      padding: 0;
      margin: 8vw auto 5vw;
      height: 5vw;
      text-align: center;
    }
  }

  .file::after {
    content: "ファイルを選択";
    display: block;
    width: 80vw;
    height: auto;
    background-color: #1f1f1f;
    text-align: center;
    line-height: 5;
    color: rgb(255, 255, 255);
    position: absolute;
    top: 0;
    left: calc(50% - 40vw);
    border-radius: 0.3em;
    font-size: pv(34);
    cursor: pointer;
    margin-top: 5vw;
  }

}

//CHECK BOX

.box_inner_bx {

  p span {
    content: "";
    display: block;
    padding: 0;
    width: 28px;
    height: 28px;
    border-radius: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    box-sizing: border-box;
  }

  p {

    .checkbox-input::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 0;
      margin-right: 10px;
      background-color: #fff;
      border: 2px solid $line;
    }

    .checkbox-input:checked + span::after {
      content: "";
      display: block;
      position: absolute;
      top: 2px;
      left: 7px;
      width: 6px;
      height: 12px;
      transform: rotate(40deg);
      border-bottom: 3px solid #d01137;
      border-right: 3px solid #d01137;
    }

  }


}

@include sp {

  .box_inner_bx {

    p span {
      content: "";
      display: block;
      padding: 0;
      width: 6vw;
      height: 6vw;
      border-radius: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      box-sizing: border-box;
    }

    p {

      .checkbox-input::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 6vw;
        height: 6vw;
        border-radius: 0;
        margin-right: 10px;
        background-color: #fff;
        border: 2px solid $line;
      }

      .checkbox-input:checked + span::after {
        content: "";
        display: block;
        position: absolute;
        top: 1vw;
        left: 2.5vw;
        width: 1.5vw;
        height: 3vw;
        transform: rotate(40deg);
        border-bottom: 3px solid #d01137;
        border-right: 3px solid #d01137;
      }
    }
  }

}


//PRIVACY CHECKBOX

.checkbox-input02 {
  display: none !important;
}

.checkbox-parts2 {
  padding-left: 35px;
  position: relative;
  margin: 10px auto 20px;
  font-size: 16px;
  font-weight: bold;
  vertical-align: middle;
}

.checkbox-parts2::before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 1px;
  border: 2px solid $line;
  margin-right: 10px;
  background-color: #fff;
}

.checkbox-input02:checked + .checkbox-parts2::after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 12px;
  transform: rotate(40deg);
  border-bottom: 3px solid #d01137;
  border-right: 3px solid #d01137;
}

@include sp {
  .checkbox-parts2 {
    padding-left: 9vw;
    position: relative;
    margin: 4vw auto 4vw;
    font-size: pv(34);
    font-weight: bold;
    vertical-align: middle;
  }

  .checkbox-parts2::before {
    content: "";
    display: block;
    position: absolute;
    top: -1.5vw;
    left: 0;
    width: 6vw;
    height: 6vw;
    border-radius: 1px;
    border: 2px solid $line;
    margin-right: 10px;
    background-color: #fff;
  }

  .checkbox-input02:checked + .checkbox-parts2::after {
    content: "";
    display: block;
    position: absolute;
    top: -.5vw;
    left: 2.5vw;
    width: 1.5vw;
    height: 3vw;
    transform: rotate(40deg);
    border-bottom: 3px solid #d01137;
    border-right: 3px solid #d01137;
  }
}

//IMPUT BUTTON

input[type="button"][disabled] {
  background-color: #ccc !important;
  background-image: none !important;
  border: solid 1px #ccc !important;
  color: #FFFFFF !important;
  cursor: default;
  background-image: url(../images/arrow.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 20px 10px;
}

input[type=button] {
  min-height: 90px;
  width: 410px;
  background: none;
  background-color: #e60012;
  border-radius: 3px;
  margin: 40px auto 20px;
  color: #FFF;
  font-size: 22px;
  font-weight: bold;
  display: block;
  background-image: url(../images/arrow.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 20px 10px;
  text-decoration: none;
  line-height: 1.4;
  text-align: center;
  cursor: pointer;
  padding: 10px;
  white-space: break-spaces;

  &.loading {
    border: solid 1px #b1b1b1;
    background: #b1b1b1 no-repeat 95% center;
    pointer-events: none;
  }
}

.mt80 {
  margin-top: 80px !important;
}

.back_btn {
  max-height: 90px;
  width: 410px;
  background: none;
  background-color: $gray;
  border-radius: 3px;
  margin: 20px auto;
  color: #FFF;
  display: block;
  background-image: url(../images/arrow_r.svg);
  background-repeat: no-repeat;
  background-position: 5% center;
  background-size: 20px 10px;
  text-decoration: none;
  padding: 35px 0;
  text-align: center;
  cursor: pointer;

  &.loading {
    border: solid 1px #b1b1b1;
    background: #b1b1b1 no-repeat 95% center;
    pointer-events: none;
  }

  p {
    font-size: 22px;
    font-weight: bold;
    display: inline-block;
  }
}

@include sp {

  input[type="button"][disabled] {
    background-size: 4vw 2vw;
  }

  input[type=button] {
    height: auto;
    width: 80vw;
    background: none;
    background-color: #e60012;
    border-radius: 3px;
    margin: 5vw auto 2.5vw;
    padding: 7vw 0;
    color: #FFF;
    font-size: pv(34);
    font-weight: bold;
    display: block;
    background-image: url(../images/arrow.svg);
    background-repeat: no-repeat;
    background-position: 95% center;
    background-size: 4vw 2vw;
    text-decoration: none;
    line-height: 1;
    text-align: center;
  }

  .mt80 {
    margin-top: 10vw !important;
  }

  .back_btn {
    max-height: inherit;
    height: auto;
    width: 80vw;
    background: none;
    background-color: $gray;
    border-radius: 3px;
    margin: 2.5vw auto;
    color: #FFF;
    display: block;
    background-image: url(../images/arrow_r.svg);
    background-repeat: no-repeat;
    background-position: 5% center;
    background-size: 4vw 2vw;
    text-decoration: none;
    padding: 7vw 0;
    text-align: center;
    display: block;

    p {
      font-size: pv(34);
      font-weight: bold;
      display: inline-block;
    }
  }
}

/*Add css custom*/
.file {
  &.btn-name::after {
    content: attr(data-after-content);
  }
}

.uploadValue {
  @apply flex items-center;

  input[type=text] {
    border: none;
    width: 100%;
    background-color: #fff;
    padding: 0;
    margin-top: 10px;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include sp {
    width: 100%;
    input[type=text] {
      border: none;
      width: 100%;
      background-color: #fff;
      font-size: 3.32356vw;
      padding: 0;
      margin: 8vw auto 5vw;
      height: 5vw;
    }

    i {
      border: none;
      width: 5%;
      background-color: #fff;
      font-size: 3.32356vw;
      padding: 0;
      margin: 8vw auto 5vw;
      height: 4vw;
    }
  }
}

.box_inner {
  label:not(:last-child) {
    .radio_segmented {
      margin-right: 20px;
    }
  }
}


/*Dohbo Viet Nam*/

body.vi {
  .required {
    background: none;
    color: $red;
  }
}
