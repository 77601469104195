.db-f-box-multiple {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  grid-auto-columns: 1fr;
}


.w-full {
  width: 100%;
}
