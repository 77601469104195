@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body.ja {
  //--- 游ゴシック ---*/
  // font-family:"Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  //--- ヒラギノ・メイリオ ---*/
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, "メイリオ", sans-serif !important;
  font-size: 100%;
}

body.vi {
  font-family: 'Roboto', sans-serif !important;
}
