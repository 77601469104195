.tabs {
  padding-bottom: 40px;
  width: 702px;
  margin: 50px auto;
  position: relative;
  z-index: 0;
  @media (max-width: 780px) {
    margin-top: 8vw;
    padding-bottom: 0;
    width: 90vw;
  }

  input[name="tab_item"] {
    display: none;
  }

  .tab_content {
    padding: 0;
    clear: both;
    overflow: hidden;

    .point_ul {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-wrap: wrap;
      margin: 50px auto;
      padding-bottom: 40px;
      border-bottom: 1px solid #F2F2F2;
      @media (max-width: 768px) {
        margin: 8vw auto;
        padding-bottom: 4vw;
      }

      .ttl-c {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F2F2F2;
        width: 37%;
        text-align: center;
        padding: 15px 0;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        line-height: 1.5;
        @media (max-width: 768px) {
          width: 35vw;
          padding: 2.5vw 0;
          font-size: 3.33333vw;
          margin-bottom: 1vw;
        }
      }

      .summary {
        word-break: break-word;
        width: 60%;
        font-size: 24px;
        font-weight: normal;
        margin: 0 0 10px 20px;
        padding: 15px 0;
        line-height: 1.4;
        @media (max-width: 768px) {
          width: 50vw;
          font-size: 3.33333vw;
          font-weight: normal;
          margin: 0 0 1vw 3vw;
          padding: 2.5vw 0;
        }
      }
    }
  }

  .tab_item {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      background: #163882;
      height: 4px;
      bottom: 1px;
      @media (max-width: 425px) {
        height: 2px;
      }
    }

    label {
      display: flex;
      align-items: center;
      text-align: center;
      padding: 30px;
      background: #f2f2f2;
      font-size: 26px;
      width: 40%;
      align-content: center;
      justify-content: space-around;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 24px;
        padding: 30px;
        width: 45%;
      }

      @media (max-width: 680px) {
        font-size: 20px;
      }

      @media (max-width: 565px) {
        font-size: 18px;
      }

      @media (max-width: 525px) {
        font-size: 16px;
        padding: 20px 2px;
      }

      @media (max-width: 320px) {
        font-size: 14px;
      }
    }

    input:checked {
      pointer-events: none;

      & + label {
        background: #ffffff;
        color: #163882;
        border-top: 4px solid #163882;
        border-left: 4px solid #163882;
        border-right: 4px solid #163882;
        border-bottom: 4px solid #fff;
        z-index: 2;
        pointer-events: none;
        @media (max-width: 425px) {
          border-top: 2px solid #163882;
          border-left: 2px solid #163882;
          border-right: 2px solid #163882;
          border-bottom: 2px solid #fff;
        }
      }
    }
  }

  .tab-empty {
    padding: 50px;
    text-align: center;
  }
}
